@font-face {
  font-family: "Aeonik Pro";
  src: url('../fonts/AeonikPro-Regular.woff2') format('woff2'),
      url('../fonts/AeonikPro-Regular.woff') format('woff');
}

@font-face {
  font-family: "Aeonik Pro";
  src: url('../fonts/AeonikPro-Bold.woff2') format('woff2'),
      url('../fonts/AeonikPro-Bold.woff') format('woff');
  font-weight: bold;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #C2C2C2;
}

div#root{
  height: 100%;
}

a {
  color: #152238;
}

a:hover {
  color: #152238;
}

.nav-item:hover {
  background-color: #FDFB85;
  border-color: #FDFB85;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: black;
}

.nav-link {
  color: black;
  text-decoration: underline;
}

.nav-link.active {
  text-decoration: none;
}

.crumb {
  color: black;
  text-decoration: none;
}

.crumb:hover {
  text-decoration: underline;
  color: black;
}

.current {
  color: #333333;
}

.filter1 {
  background-color: #943853;
  border-color: #943853;
}

.filter2 {
  background-color: #FDFB85;
  border-color: #FDFB85;
}

.filter3 {
  background-color: #A5A796;
  border-color: #A5A796;
}

.filter4 {
  background-color: #F2C47B;
  border-color: #F2C47B;
}

.filter5 {
  background-color: #ADCF8C;
  border-color: #ADCF8C;
}

.filter6 {
  background-color: #C8E9F3;
  border-color: #C8E9F3;
}

.filter7 {
  background-color: #D7E575;
  border-color: #D7E575;
}

.filter1:hover, .filter2:hover, .filter3:hover, .filter4:hover, .filter5:hover, .filter6:hover, .filter7:hover{
  color: white;
  background-color: black;
  border-color: black;
}

.episode-tile {
  background-color: #D3D3D3;
  border-color: #D3D3D3;
  font-size: x-small; 
  padding: 9px
}

.dud {
  visibility: hidden;
}

.btn-thematic1 {
  background-color: #9CB083;
  border-color: #9CB083;
}

.btn-thematic2 {
  background-color: #AA6DC7;
  border-color: #AA6DC7;
}

.btn-thematic3 {
  background-color: #A5A796;
  border-color: #A5A796;
}

.btn-logobutton-top, .btn-logobutton-top:hover{
  background-color: black;
  flex: auto;
  border-radius: 0px;
  height: 8vh;
}

.btn-logobutton-bottom, .btn-logobutton-bottom:hover{
  background-color: black;
  flex: auto;
  border-radius: 0px;
  height: 6vh;
}

.btn-logobutton-rounded, .btn-logobutton-rounded:hover{
  background-color: black;
  flex: auto;
  border-radius: 15px;
  height: 8vh;
}

.stressed {
  text-decoration: underline;
}

.thematic0 {
  background-color: #CFCECE;  
  border-color: #CFCECE;
}

.thematic1 {
  background-color: #9CB083;
  border-color: #9CB083;
}

.thematic2 {
  background-color: #AA6DC7;
  border-color: #AA6DC7;
}

.thematic3 {
  background-color: #A5A796;
  border-color: #A5A796;
}

.descselected {
  background-color: black;
}

.btn-descselected, .btn-descselected:hover {
  background-color: black;
  color: white;
}

.axis1 {
  background-color: #943853;
  border-color: #943853;
}

.axis2 {
  background-color: #FDFB85;
  border-color: #FDFB85;
}

.axis3 {
  background-color: #A5A796;
  border-color: #A5A796;
}

.axis4 {
  background-color: #F2C47B;
  border-color: #F2C47B;
}

.axis5 {
  background-color: #ADCF8C;
  border-color: #ADCF8C;
}

.axis6 {
  background-color: #C8E9F3;
  border-color: #C8E9F3;
}

.axis7 {
  background-color: #D7E575;
  border-color: #D7E575;
}

.episode-tile-new {
  background-color: transparent;
  border-color: #D3D3D3;
  color: black;
}

.empty {
  visibility: hidden;
}

.gridsquare {
  height: 15vh;
}

.quote{
  font-style: italic;
  font-size: small;
  background-color: blue;
  color: white;
}

.quote-truncate{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.modal-quote{
  height: 60vh;  
  font-size: xx-large;
  background-color: blue;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content{
  background-color: #333333;
  color: #bec3c9;
  border-color: #bec3c9;
}

.image-square{
  height: 100%;
  width: auto;
}

.btn-close{
  height: 2.5em;
  width: 2.5em;
  background-image: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-x-lg' viewBox='0 0 16 16'><path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z'/> </svg>"); 
  background-size: 2.5rem 2.5rem;
}

.modal-image{
  color: white;
}